export enum API_TYPES {
  SETTINGS_API = 'SETTINGS_API',
}

const developmentApi: Record<API_TYPES, string> = {
  // SETTINGS_API: 'https://open-pa-api-ch-elektra.os-dev.io/api',
  // SETTINGS_API: 'https://open-pa-api-ch-witch.os-dev.io/api',
  SETTINGS_API: 'https://open-pa-api-ch-hulk.os-dev.io/api',
}

const productionApi: Record<API_TYPES, string> = {
  SETTINGS_API: 'WPP_TEMPLATE_SETTINGS_API',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
