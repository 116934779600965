/* eslint-disable @typescript-eslint/no-unused-vars */
import { WppCard, WppInput, WppToggle, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { useFetchTenantSettings } from 'api/queries/useFetchTenantSettings'
import CustomDropdown from 'components/common/dropdown/Dropdown'
import { Flex } from 'components/common/flex/Flex'

import styles from './General.module.scss'

export default function GeneralPage() {
  // const {
  //   data: tenantSettings,
  //   error: errorTenantSettings,
  //   isLoading: isLoadingTenantSettings,
  // } = useFetchTenantSettings()

  const [isEnabled, setIsEnabled] = useState(true)

  const OPTIONS_LIST = ['ChatGPT 4']

  const handleChangeLLM = (event: string) => {
    console.log('event', event)
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.settingsContainer}>
        <WppTypography type="3xl-heading">AI</WppTypography>

        <Flex gap={24} className={styles.mt24}>
          <Flex className={styles.w50} direction="column" gap={16}>
            {/* WPP AI */}
            <WppCard size="xl" className={styles.disabled}>
              <div slot="header">
                <WppTypography type="xl-heading">WPP AI</WppTypography>
              </div>

              <Flex justify="between" align="center">
                <WppTypography type="s-body">Enable WPP AI</WppTypography>

                <WppToggle
                  checked={isEnabled}
                  name="options"
                  onWppChange={({ detail: { checked } }) => setIsEnabled(checked)}
                />
              </Flex>
              <WppTypography type="xs-body" className={styles.mt10}>
                WPP AI is a chat-based assistant with natural language processing capabilities and a toolbar which
                serves as a launchpad for WPP apps and tools.
              </WppTypography>
            </WppCard>

            {/* Default LLM */}
            <WppCard size="xl">
              <div slot="header">
                <WppTypography type="xl-heading">Default LLM</WppTypography>
              </div>

              <WppTypography type="xs-body" className={styles.mt10}>
                Customize your AI chat experience by selecting your preferred Language Model (LLM), the powerhouse
                behind understanding and generating responses.
              </WppTypography>

              <CustomDropdown className={styles.mt12} options={OPTIONS_LIST} onWppChange={handleChangeLLM} />
            </WppCard>

            {/* Semantic layer */}
            <WppCard size="xl" className={styles.disabled}>
              <div slot="header">
                <WppTypography type="xl-heading">Semantic layer</WppTypography>
              </div>

              <Flex justify="between" align="center">
                <WppTypography type="s-body">Enable semantic layer</WppTypography>

                <WppToggle
                  checked={false}
                  name="options"
                  // onWppChange={({ detail: { checked } }) => setIsEnabled(checked)}
                />
              </Flex>
              <WppTypography type="xs-body" className={styles.mt10}>
                The semantic layer acts as an intermediary between the AI chat and the LLM services, interpreting the
                chatbot's queries and ensuring that they are correctly executed.
              </WppTypography>
            </WppCard>
          </Flex>

          <WppCard size="xl" className={clsx(styles.w50, styles.disabled)}>
            <div slot="header">
              <WppTypography type="xl-heading">Vector Database</WppTypography>
            </div>

            <Flex justify="between">
              <WppTypography type="s-body">Enable vector database</WppTypography>

              <WppToggle
                checked={true}
                name="options"
                // onWppChange={({ detail: { checked } }) => setIsEnabled(checked)}
              />
            </Flex>
            <WppTypography type="xs-body" className={styles.mt10}>
              The vector database will allow users to specify files and databases for conversion into a searchable
              format accessible via chat interface, with options for accessing it either through a semantic layer with
              precise phrases or a designated service mention.
            </WppTypography>

            <Flex justify="between" className={styles.mt16}>
              <WppTypography type="s-body">Enable vector database</WppTypography>

              <WppToggle
                checked={true}
                name="options"
                // onWppChange={({ detail: { checked } }) => setIsEnabled(checked)}
              />
            </Flex>
            <WppTypography type="xs-body" className={styles.mt10}>
              The vector database will allow users to specify files and databases for conversion into a searchable
              format accessible via chat interface, with options for accessing it either through a semantic layer with
              precise phrases or a designated service mention.
            </WppTypography>

            <WppInput
              name="email"
              labelConfig={{ text: 'Service mention', icon: 'info' }}
              // placeholder="Email"
              value="@vectordb"
              required
              className={styles.mt10}
              // onWppChange={handleEmailChange}
            />
            <WppInput
              name="email"
              labelConfig={{ text: 'Short description', icon: 'info' }}
              // placeholder="Email"
              value="VectorDB"
              required
              className={styles.mt10}
              // onWppChange={handleEmailChange}
            />
          </WppCard>
        </Flex>
      </div>
    </div>
  )
}
