import { useOs } from '@wpp-open/react'
import { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import GeneralPage from 'components/pages/general/General'

export function App() {
  // TODO check for settings permissions

  // const { hasAccessToChatAssistant } = useHasPermissions()

  // if (!hasAccessToChatAssistant) {
  //   return null
  // }

  const { osContext } = useOs()
  console.log('AI SETTINGS')

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<GeneralPage />} />
          <Route path="/general" element={<GeneralPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
      {/* <div className={styles.assistantContainer}> */}

      <div id="modals" />
      {/* </div> */}
    </BrowserRouter>
  )
}
