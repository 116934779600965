// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iVHF_{min-height:calc(100vh - var(--wpp-os-header-height));background:#f8f9fb}.vF3iJ{max-width:var(--wpp-os-content-max-width);margin:0 auto;padding:28px}.vZsSn{width:50%}.pK26p{margin-top:10px}.CU1zl{margin-top:12px}.mSFJT{margin-top:16px}.pgYmr{margin-top:24px}.X45Fy{opacity:.5;pointer-events:none;-webkit-user-select:none;user-select:none}", "",{"version":3,"sources":["webpack://./src/components/pages/general/General.module.scss"],"names":[],"mappings":"AAAA,OACE,oDAAA,CACA,kBAAA,CAGF,OACE,yCAAA,CACA,aAAA,CACA,YAAA,CAGF,OACE,SAAA,CAGF,OACE,eAAA,CAGF,OACE,eAAA,CAGF,OACE,eAAA,CAGF,OACE,eAAA,CAGF,OACE,UAAA,CACA,mBAAA,CACA,wBAAA,CAAA,gBAAA","sourcesContent":[".pageContainer {\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  background: #f8f9fb;\n}\n\n.settingsContainer {\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n  padding: 28px;\n}\n\n.w50 {\n  width: 50%;\n}\n\n.mt10 {\n  margin-top: 10px;\n}\n\n.mt12 {\n  margin-top: 12px;\n}\n\n.mt16 {\n  margin-top: 16px;\n}\n\n.mt24 {\n  margin-top: 24px;\n}\n\n.disabled {\n  opacity: 0.5;\n  pointer-events: none;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "iVHF_",
	"settingsContainer": "vF3iJ",
	"w50": "vZsSn",
	"mt10": "pK26p",
	"mt12": "CU1zl",
	"mt16": "mSFJT",
	"mt24": "pgYmr",
	"disabled": "X45Fy"
};
export default ___CSS_LOADER_EXPORT___;
